import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Paging } from '@/api/base';
import { ImportRes, CustomerInvoiceResource } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { handleImportError, messageError, showWarningConfirm, translation } from '@/utils';
import { Message } from 'element-ui';
import { ImportTemplateEnum, InvoiceTypeEnum } from '@/resource/enum';
import { cloneDeep } from 'lodash-es';
import { ImportFile } from '@/views/dialogs/index';
import { ApiResponse } from '@/api/axios';
import { customerInvoiceService } from '@/api';
import AddCustomerInvoice from './add-customer-invoice/add-customer-invoice.vue';
@Component({
  name: 'CustomerInvoice',
  components: { OsTable, OsTableQuery, OsPagination, OsTableOperation, ImportFile, AddCustomerInvoice }
})
export default class CustomerInvoice extends Vue {
  public tableOption: OsTableOption<CustomerInvoiceResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<CustomerInvoiceResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'companyName',
      label: 'invoice.companyName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'taxCode',
      label: 'invoice.taxCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'taxRate',
      label: 'invoice.taxRate',
      minWidth: '100px'
    },
    {
      prop: 'invoiceType',
      label: 'invoice.invoiceType',
      minWidth: '120px',
      formatter: (rowData: object): string | undefined => {
        return this.invoiceTypeOptions.find(x => x.value === (rowData as CustomerInvoiceResource).invoiceType)?.label;
      }
    },
    {
      prop: 'bankName',
      label: 'invoice.bankName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'bankAccount',
      label: 'invoice.bankAccount',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'address',
      label: 'invoice.address',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'contactNumber',
      label: 'invoice.contactNumber',
      showOverflowTooltip: true,
      minWidth: '150px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'customer:invoice:save',
      handleClick: (): void => {
        this.openInvoiceDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'customer:invoice:importData',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'customer:invoice:batchDelete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeleteInvoice();
      }
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<CustomerInvoiceResource> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'customer:invoice:edit',
        handleClick: (item: CustomerInvoiceResource): void => {
          this.openInvoiceDialog(item);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'customer:invoice:delete',
        handleClick: (item: CustomerInvoiceResource): void => {
          this.deleteInvoice(item);
        }
      }
    ]
  };
  /**
   * 控制导入的dialog显示隐藏
   */
  public importDialogVisible = false;
  public importTitle = 'dialog.importInvoice';
  public editRow: CustomerInvoiceResource | null = null;
  public dialogVisible = false;
  public totalData = 0;
  /**
   * 分类导入模板的名称
   */
  public importTemplate: ImportTemplateEnum = ImportTemplateEnum.invoiceCustomer;
  public invoiceTypeOptions: Array<{ label: string; value: InvoiceTypeEnum }> = [
    {
      label: '普通发票',
      value: InvoiceTypeEnum.normalInvoice
    },
    {
      label: '增值税专用发票',
      value: InvoiceTypeEnum.valueAddedTaxInvoice
    },
    {
      label: '形式发票',
      value: InvoiceTypeEnum.proformaInvoice
    }
  ];
  private selectedRows: Array<CustomerInvoiceResource> = [];

  private queryForm: Partial<{
    keywords?: string;
    /**
     * 企业id
     */
    companyId: number;
  }> = {
    keywords: ''
  };

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private companyId = Number(this.$route.query.companyId);

  public activated(): void {
    if (!isNaN(this.companyId) && this.companyId !== Number(this.$route.query.companyId)) {
      this.companyId = Number(this.$route.query.companyId);
      this.reloadData();
    }
  }

  public created(): void {
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.invoiceTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<CustomerInvoiceResource>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(data: CustomerInvoiceResource): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public importSuccess(path: string): void {
    customerInvoiceService
      .importData(path, this.queryForm.companyId!)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  private openInvoiceDialog(data: CustomerInvoiceResource | null = null): void {
    if (data) {
      this.editRow = cloneDeep(data);
    }
    this.dialogVisible = true;
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<CustomerInvoiceResource>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add' && x.operationType !== 'import') {
        x.disabled = value.length === 0;
      }
    });
  }

  private deleteInvoice(data: CustomerInvoiceResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await customerInvoiceService.delete(data.id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchDeleteInvoice(): Promise<void> {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await customerInvoiceService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private loadData(): void {
    this.tableOption.loading = true;
    this.queryForm.companyId = this.companyId;
    customerInvoiceService
      .getList(this.queryForm as CustomerInvoiceResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }
}
