import { customerInvoiceService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { InvoiceTypeEnum } from '@/resource/enum';
import { CustomerInvoiceResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { taxRate } from '@/utils/validate';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class AddCustomerInvoice extends mixins(DialogMixin) {
  @Prop({ required: false, default: null }) public invoice!: CustomerInvoiceResource;

  public invoiceForm: {
    /**
     * 所属公司
     */
    companyName: string;
    /**
     * 企业税号
     */
    taxCode: string;
    /**
     * 税率
     */
    taxRate: number | null;
    /**
     * 发票类型
     */
    invoiceType: InvoiceTypeEnum;
    /**
     * 开户行
     */
    bankName: string;
    /**
     * 对公账号
     */
    bankAccount: string;
    /**
     * 开票地址
     */
    address: string;
    /**
     * 联系人
     */
    contactNumber: string;
  } = {
    companyName: '',
    taxCode: '',
    taxRate: null,
    invoiceType: InvoiceTypeEnum.normalInvoice,
    bankName: '',
    bankAccount: '',
    address: '',
    contactNumber: ''
  };

  public resourceFormRules = {
    companyName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('invoice.inputCompanyName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          customerInvoiceService
            .checkCompanyName(value, Number(this.$route.query.companyId), this.invoice?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.customerNameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    taxCode: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('invoice.inputTaxCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    taxRate: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('invoice.inputTaxRate')));
            return;
          }
          if (!taxRate(value.toString())) {
            callback(new Error(translation('invoice.taxRateValid')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    invoiceType: [
      {
        required: true,
        validator: (rule: any, value: InvoiceTypeEnum, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('invoice.selectInvoiceType')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    bankName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('invoice.inputBankName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    bankAccount: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('invoice.inputBankAccount')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    address: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('invoice.inputAddress')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    contactNumber: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('invoice.inputContactNumber')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public invoiceTypeOptions: Array<{ label: string; value: InvoiceTypeEnum }> = [
    {
      label: '普通发票',
      value: InvoiceTypeEnum.normalInvoice
    },
    {
      label: '增值税专用发票',
      value: InvoiceTypeEnum.valueAddedTaxInvoice
    },
    {
      label: '形式发票',
      value: InvoiceTypeEnum.proformaInvoice
    }
  ];
  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (!this.invoice) {
      this.operationType = 'add';
      this.title = 'invoice.addInvoice';
      return;
    }
    this.operationType = 'edit';
    this.title = 'invoice.editInvoice';
    this.$nextTick(() => {
      Object.assign(this.invoiceForm, this.invoice);
    });
  }

  public dialogClosed(): void {
    (this.$refs.invoiceForm as Form).resetFields();
    this.$emit('dialog-closed');
  }

  public onSubmit(): void {
    (this.$refs.invoiceForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await customerInvoiceService.post({
            ...this.invoiceForm,
            companyId: this.$route.query.companyId
          } as any);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await customerInvoiceService.put({
          ...this.invoiceForm,
          companyId: this.$route.query.companyId
        } as any);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.invoiceForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
